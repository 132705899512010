import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { CONFIG } from 'src/config-global';
import { alpha, stylesMode, textGradient } from 'src/theme/styles';

import SvgColor from 'src/components/svg-color';
import { varFade, MotionViewport } from 'src/components/animate';

import { SectionTitle } from './components/section-title';
import { CircleSvg, FloatLine, FloatPlusIcon } from './components/svg-elements';
import { ReactSVG } from 'react-svg';
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function HomeMinimal({ sx, ...other }) {
  const theme = useTheme();

  const renderLines = (
    <>
      <FloatPlusIcon sx={{ top: 72, left: 72 }} />
      <FloatPlusIcon sx={{ bottom: 72, left: 72 }} />
      <FloatLine sx={{ top: 80, left: 0 }} />
      <FloatLine sx={{ bottom: 80, left: 0 }} />
      <FloatLine vertical sx={{ top: 0, left: 80 }} />
    </>
  );

  const renderDescription = (
    <>
      <SectionTitle
        // caption="Visualizing Success"
        title={
          <Stack direction={'row'} alignItems={'center'} spacing={1.5}>
            <Typography variant={'h2'} color={'black'} fontSize={48}>
              {`How is `}
            </Typography>
            <Typography variant={'h2'} color={'#2BB714'} fontSize={48}>
              {`Coursepals`}
            </Typography>
            <Box
              component="span"
              sx={{
                opacity: 0.4,
                display: 'inline-block',
                ...textGradient(
                  `to right, ${theme.palette.text.primary}, ${alpha(theme.palette.text.primary, 0.2)}`,
                ),
              }}
            >
              different?
            </Box>
          </Stack>
        }
        sx={{ mb: { xs: 5, md: 8 }, textAlign: { xs: 'center', md: 'left' } }}
      />

      <Stack
        spacing={6}
        sx={{
          maxWidth: { sm: 560, md: 400 },
          mx: { xs: 'auto', md: 'unset' },
        }}
      >
        {ITEMS.map((item) => (
          <Box
            component={m.div}
            key={item.title}
            variants={varFade({ distance: 24 }).inUp}
            gap={3}
            display="flex"
          >
            {/*<SvgColor src={item.icon} sx={{ width: 40, height: 40 }} />*/}
            <ReactSVG style={{ width: '42px', height: '42px' }} src={item.icon} />
            <Stack spacing={1}>
              <Typography variant="h5" component="h6">
                {item.title}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{item.description}</Typography>
            </Stack>
          </Box>
        ))}
      </Stack>
    </>
  );

  const renderImg = (
    <Stack
      component={m.div}
      variants={varFade({ distance: 24 }).inRight}
      alignItems="center"
      justifyContent="center"
      sx={{ height: 1, position: 'relative' }}
    >
      <Box
        sx={{
          left: 0,
          width: 720,
          borderRadius: 2,
          position: 'absolute',
          bgcolor: 'background.default',
          boxShadow: (theme) =>
            `-40px 40px 80px 0px ${alpha(theme.palette.grey['500'], 0.16)}`,
          [stylesMode.dark]: {
            boxShadow: (theme) =>
              `-40px 40px 80px 0px ${alpha(theme.palette.common.black, 0.16)}`,
          },
        }}
      >
        <Box
          component="img"
          alt="Home Chart"
          src={`/assets/images/landing_page/App_TotalInstalled.png`}
          sx={{ width: 720 }}
        />
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="section"
      sx={{
        overflow: 'hidden',
        position: 'relative',
        py: { xs: 10, md: 20 },
        ...sx,
      }}
      {...other}
    >
      <MotionViewport>
        {renderLines}

        <Container sx={{ position: 'relative' }}>
          <Grid container columnSpacing={{ xs: 0, md: 8 }} sx={{ position: 'relative', zIndex: 9 }}>
            <Grid xs={12} md={6} lg={7}>
              {renderDescription}
            </Grid>

            <Grid md={6} lg={5} sx={{ display: { xs: 'none', md: 'block' } }}>
              {renderImg}
            </Grid>
          </Grid>

          <CircleSvg variants={varFade().in} sx={{ display: { xs: 'none', md: 'block' } }} />
        </Container>
      </MotionViewport>
    </Stack>
  );
}

// ----------------------------------------------------------------------

const ITEMS = [
  {
    icon: `/assets/icons/landing_page/diffrent_icon_1.svg`,
    title: 'Customize your own academic GPTs',
    description: 'Upload your course material files to make GPTs as experts',
  },
  {
    icon: `/assets/icons/landing_page/diffrent_icon_2.svg`,
    title: '1-Click APIs Switching for precise answers',
    description: 'Easy to check different answers from various academic resources',
  },
  {
    icon: `/assets/icons/landing_page/diffrent_icon_3.svg`,
    title: 'Co-create GPTs with team members',
    description: 'Co-training the GPTs and share with team members to boost collaboration efficiency ',
  },
];
