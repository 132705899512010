import axios from "axios";
// config
import { HOST_API } from "../config-global";
import uuidv4 from "./uuidv4";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
	res => res,
	error => Promise.reject((error.response && error.response.data) || "Something went wrong"),
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async args => {
	const [url, config] = Array.isArray(args) ? args : [args];

	const res = await axiosInstance.get(url, { ...config });

	return res.data.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
	agent: {
		agents: "/api/v1/agent",
		agent: id => `/api/v1/agent/${id}`,
		profile: id => `/api/v1/agent/${id}/profile`,
		parameters: id => `api/v1/agent/${id}/parameters`,
		apps: id => `/api/v1/agent/${id}/apps`,
		recommend: "/api/v1/agent/recommend",
		comment: (id, page) => `/api/v1/comment/${id}?id=${id}&page=${page}&limit=10`,
		addComment: "/api/v1/comment",
		deleteComment: id => `/api/v1/comment/${id}`,
	},
	chat: {
		chat: "/api/v1/chat/",
		conversations: "/api/v1/chat/conversations",
		conversation: "/api/v1/chat/conversation",
		delete_conversation: id => `/api/v1/chat/conversation/${id}`,
		conversation_history: id => `/api/v1/chat/conversation/${id}/history`,
		in_conversation: (id) =>
			`/api/v1/chat/conversation/${id}`,
		session: "/api/v1/chat/session",
		sessions: id => `/api/v1/chat/sessions?conversation_id=${id}`,
		message: "/api/v1/chat/message",
		delete_message: session_id => `/api/v1/chat/message/${session_id}`,
		audioInput: "/api/v1/chat/voice",
		textToSpeech: "/api/v1/chat/textToSpeech",
		messages: session_id => `/api/v1/chat/messages?session_id=${session_id}`,
		message_callback: (conversation_id, session_id, message_id) =>
			`/api/v1/chat/conversation/${conversation_id}/session/${session_id}/message/${message_id}/callback`,
		deleteSession: session_id => `/api/v1/chat/session/${session_id}`,
		updateSession: session_id => `/api/v1/chat/session/${session_id}`,
		newMessageStream:'/api/v1/chat/newMessageStream'
	},
	chain: {
		in_chain: id => `/api/v1/chain/${id}`,
		chain: "/api/v1/chain",
	},
	plugin: {
		mutate_key:key => typeof key === "string" && key.startsWith("/api/v1/plugin"),
		plugin: "/api/v1/plugin",
		plugins: (page) => `/api/v1/plugin/search?page=${page}&limit=20`,
		in_plugin: id => `/api/v1/plugin/${id}`,
		generateList: "/api/v1/plugin/generatePluginList",
		recommend: "/api/v1/plugin/recommend",
		tool: {
			root:"/api/v1/tool",
			in_tool: id => `/api/v1/tool/${id}`,
		}
	},
	data_source: {
		data_sources: (page, limit) => `/api/v1/plugin/data_source/search?page=${page}&limit=20`,
		bind: id => `/api/v1/plugin/data_source/${id}/bind`,
		files: (id, source) => `/api/v1/plugin/${id}/data?source=${source}`,
	},
	model: {
		model: page => `/api/v1/model/search?page=${page}&limit=20`,
		models: (page, limit) => `/api/v1/model?page=${page}&limit=${limit}`,
		model_options: "/api/v1/model/options",
		recommend: "/api/v1/model/recommend",
	},
	template: {
		template: "/api/v1/plugin/template",
		templates: (page, limit) => `/api/v1/plugin/template/search?page=${page}&limit=20`,
		template_id: id => `/api/v1/plugin/template/${id}`,
	},
	prompt: {
		new_prompt: "/api/v1/prompt",
		prompts: (page, limit) => `/api/v1/prompt/search?page=${page}&limit=20`,
		prompt: id => `/api/v1/prompt/${id}`,
		recommend: "/api/v1/prompt/recommend",
	},
	dynamic: {
		root: "/api/v1/dynamic",
	},
	cloud: {
		// files: (page=1,limit=100,folder_id) => `/api/v1/cloud?page=${page}&limit=${limit}&folder_id=${folder_id}`,
		files: "/api/v1/cloud",
		folder: "/api/v1/cloud/folder",
		delete: "/api/v1/cloud/delete",
		usage: "/api/v1/cloud/usage",
	},
	image: {
		root: "/api/v1/storage/image",
		normal: id => `https://forward.pic.coursepals.ai/${id||"afabba78-7b2a-4cd2-a80f-e526ae2a53d6"}`,
		avatar: id => `https://forward.pic.coursepals.ai/${id||"afabba78-7b2a-4cd2-a80f-e526ae2a53d6"}?x-oss-process=style/avatar`,
	},
	storage: {
		file: "/api/v1/storage/file",
		image: "/api/v1/storage/image",
	},
	favorite: {
		favorite: "/api/v1/favorite",
	},
	captcha:"/api/v1/captcha",
	sms:{
		code:"/api/v1/sms/code",
	},
	email:{
		code:"/api/v1/email/code",
	},
	owner:{
		root: "/api/v1/owner",
	},
	auth: {
		me: "/api/v1/user",
		logout: "/api/v1/auth/logout",
		phone_login: "/api/v1/auth/login/phone",
		password_login: "/api/v1/auth/login/password",
		passport_login: "/api/v1/auth/login/passport",
		register: "/api/v1/auth/register",
		reset: "/api/v1/auth/forget",
		send_sms: "/api/v1/auth/sms/send",
		send_email: "/api/v1/auth/email/send",
		callback: {
			root: "/api/v1/auth/callback",
			app: app => `/api/v1/auth/passport/${app}/callback`,
		},
		state: "/api/v1/auth/passport/state",
		forget: "/api/v1/auth/recover",
		get_state: (state, token,app,event) => `/api/v1/auth/passport/state?state=${state}&token=${token}&app=${app}&event=${event}`,
		refresh: "/api/v1/auth/refresh",
		oauth2: {
			github: "https://github.com/login/oauth/authorize?client_id=8427ecad1e46ca68cffc",
			microsoft: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=6dc9fd2a-b1ae-46a3-a6c7-76495d578c56&scope=https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&response_type=code",
			google: "https://accounts.google.com/o/oauth2/v2/auth?client_id=746408512926-v4baqb0k37pvs8i33no8cotjbin77cbe.apps.googleusercontent.com&response_type=code&include_granted_scopes=true&scope=https://www.googleapis.com/auth/userinfo.profile",
		},
	},
	organization: {
		organization: "/api/v1/organization",
		in_organization: id => `/api/v1/organization/${id}`,
		invite: {
			invite: id => `/api/v1/organization/${id}/invite`,
			setting: id => `/api/v1/organization/${id}/invite/setting`,
			details: code => `/api/v1/organization/invite?code=${code}`,
		},
		user: (id, user_id) => `/api/v1/organization/${id}/user?id=${user_id}`,
		transfer: (id, user_id) => `/api/v1/organization/${id}/transfer?id=${user_id}`,
	},
	app: {
		root: "/api/v1/app",
	},
	feedback: {
		feedback: "/api/v1/feedback",
	},
	user: {
		me: "/api/v1/user",
		phone: "/api/v1/user/phone",
		email: "/api/v1/user/email",
		passport: "/api/v1/user/passport",
		password: "/api/v1/user/password",
	},
	gpt:{
		root: "/api/v1/gpt",
		in_gpt: id => `/api/v1/gpt/${id}`,
		generate_prompt:prompt=>`/api/v1/gpt/generatePrompt/${prompt}`,
		push2channel:"/api/v1/stream/createBotToChannel"
	},
	waitlist: {
		signup: 'https://api.getwaitlist.com/api/v1/signup'
	}
};
