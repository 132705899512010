'use client';

import Stack from '@mui/material/Stack';

import { ScrollProgress, useScrollProgress } from 'src/components/animate/scroll-progress';


import BackToTop from 'src/components/animate/back-to-top/back-to-top';
import HomeHero from 'src/sections/home/home-hero';
import HomeMinimal from 'src/sections/home/home-minimal';
import HomeHugePackElements from 'src/sections/home/home-hugepack-elements';
import HomeForDesigner from 'src/sections/home/home-for-designer';
import HomeHighlightFeatures from 'src/sections/home/home-highlight-features';
import HomeIntegrations from 'src/sections/home/home-integrations';
import HomePricing from 'src/sections/home/home-pricing';
import HomeTestimonials from 'src/sections/home/home-testimonials';
import HomeFAQs from 'src/sections/home/home-faqs';
import HomeZoneUI from 'src/sections/home/home-zone-ui';
import HomeAdvertisement from 'src/sections/home/home-advertisement';

// ----------------------------------------------------------------------

export default function HomeView() {
  const pageProgress = useScrollProgress();

  return (
    <>
      <ScrollProgress
        variant="linear"
        progress={pageProgress.scrollYProgress}
        sx={{ position: 'fixed' }}
      />

      <BackToTop />

      <HomeHero />

      <Stack sx={{ position: 'relative', bgcolor: 'background.default' }}>
        <HomeIntegrations />
        <HomeMinimal />

        {/*<HomeHugePackElements />*/}

        {/*<HomeForDesigner />*/}

        <HomeHighlightFeatures />


        {/*<HomePricing />*/}

        {/*<HomeTestimonials />*/}

        {/*<HomeFAQs />*/}

        {/*<HomeZoneUI />*/}

        {/*<HomeAdvertisement />*/}
      </Stack>
    </>
  );
}
