import { enqueueSnackbar } from "notistack";

/**
 * @param {message} 输出信息
 * @param {messageType} 输出信息类型
 * @returns {undefined}
 */
export default function EnqueueSnackbar(message, messageType) {
	enqueueSnackbar(message, {
		variant: messageType,
		autoHideDuration: 1000,
		anchorOrigin: {
			vertical: "top",
			horizontal: "center",
		},
	});
}
